<template>
  <div ref="main" class="m-main-wrap" v-loading="loadList">
    <div ref="topDom">
      <!-- 搜索 start -->
      <div class="flex-bt flex-wrap lhx40">
        <div>
          <el-input
            class="w200 mr10 mb20"
            placeholder="请输入關鍵字"
            clearable
            v-model="filters.title"
            @clear="search"
            @keyup.enter.native="search"
          ></el-input>
          <el-button class="mb20" type="primary" @click="search"
            >搜尋</el-button
          >
          <el-button class="mb20" type="primary" @click="createWords"
            >新增</el-button
          >
          <el-button
            class="mb20 upload-button"
            type="primary"
            @click="importData"
            >導入
            <!-- accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" -->
            <uploadImg
              apear="button"
              :multiple="false"
              @confirm="confirmFile"
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ></uploadImg>
          </el-button>
          <el-button class="mb20" type="primary" @click="exportData"
            >導出</el-button
          >
        </div>
        <div>更新时间：{{ tableData.update_time | formatTime }}</div>
      </div>
    </div>
    <!-- 内部侧边栏 -->
    <el-container>
      <el-main>
        <!-- 表格 -->
        <el-table
          :data="tableData.lists.data"
          style="width: 100%"
          :height="tableHeight"
        >
          <el-table-column width="60" label="NO.">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="違禁字詞">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-link
                class="mr20"
                type="primary"
                @click="editWords(scope.$index)"
                >編輯</el-link
              >
              <el-popconfirm
                confirmButtonText="確定"
                cancelButtonText="取消"
                icon="el-icon-info"
                iconColor="red"
                title="確定刪除嗎？"
                @onConfirm="handleDelete(scope.$index)"
              >
                <el-link slot="reference" type="danger" class="ml10"
                  >删除</el-link
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 ｜ 批量移动-->
        <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
          <el-pagination
            background
            hide-on-single-page
            :page-size="tableData.lists.per_page"
            :current-page="tableData.lists.current_page * 1"
            :total="tableData.lists.total"
            @current-change="changePage"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      width="600px"
      :title="type == 'create' ? '添加' : '編輯' + '違禁字詞'"
      :append-to-body="true"
      :modal-append-to-body="true"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <div>
        <el-form ref="ruleForm" :model="form">
          <el-form-item label="違禁字詞" prop="note">
            <el-input v-model="form.name" placeholder="请输入內容"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirm" :loading="loadingNote"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { calTableHeight } from '@/common/tool'
export default {
  data() {
    return {
      loadList: false,
      loadingNote: false,
      curItem: {},
      curIndex: -1,
      showDialog: false,
      filters: {
        title: '',
      },
      type: '',
      form: {
        name: '',
      },
      options: [],
      tableData: {
        update_time: '',
        lists: {
          data: [],
          current_page: 1,
          per_page: 15,
          total: 0,
        },
      },
      tableHeight: 100, //表格高度
    }
  },
  created() {
    this.getDataList()
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = calTableHeight(this)
    })
  },
  methods: {
    async handleDelete(index) {
      let id = this.tableData.lists.data[index].id
      let res = await this.api.goods.deleteWords(id)
      if (res && res.code == 0) {
        this.$message({
          message: '刪除成功',
          type: 'success',
        })
        this.tableData.lists.data.splice(index, 1)
      }
    },
    async getDataList(page) {
      this.loadList = true
      let res = await this.api.goods.getWords({
        page,
        ...this.filters,
      })
      if (res && res.code == 0) {
        this.tableData = res.data
      }
      this.loadList = false
    },
    confirmFile(arr) {
      let file = arr.files[0]
      let formData = new FormData()
      formData.append('file', file)
      this.api.goods.importWords(formData).then((res) => {
        if (res && res.code == 0) {
          this.getDataList()
        }
      })
    },
    createWords() {
      this.showDialog = true
      this.type = 'create'
      this.form = {
        name: '',
      }
    },
    editWords(index) {
      this.type = 'edit'
      this.curIndex = index
      this.curItem = this.tableData.lists.data[index]
      this.form = { ...this.curItem }
      this.showDialog = true
    },
    search() {
      if (!isNaN(this.filters.max) && !isNaN(this.filters.min)) {
        if (
          this.filters.max &&
          this.filters.min &&
          this.filters.max < this.filters.min
        ) {
          this.$message({
            message: '最高價格不能小于最低價格',
            type: 'warning',
          })
          return
        }
      }
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    closeDialog() {
      this.loadingNote = false
      this.showDialog = false
      this.$refs['ruleForm'].resetFields()
    },
    confirm() {
      this.saveData()
    },
    importData() {},
    async saveData() {
      this.loadingNote = true
      let id
      let res
      let page
      if (this.type == 'create') {
        page = 1
        res = await this.api.goods.createWords(this.form.name)
      } else {
        page = this.tableData.lists.current_page
        id = this.tableData.lists.data[this.curIndex].id
        res = await this.api.goods.editWords(id, this.form.name)
      }
      if (res && res.code == 0) {
        this.closeDialog()
        this.$message({
          message: '保存成功',
          type: 'success',
        })
        this.getDataList(page)
      }
      this.loadingNote = false
    },
    exportData() {
      this.getExportData()
    },
    async getExportData() {
      let res = await this.api.goods.exportWords()
      var a = document.createElement('a')
      a.href = res.data
      document.body.append(a)
      a.click()
      document.body.removeChild(a)
    },
  },
}
</script>
<style scoped>
/deep/.el-form-item__label {
  color: #999;
}
/deep/.el-form--inline .el-form-item {
  padding-right: 20px;
}
.upload-button {
  position: relative;
  z-index: 1;
}
</style>
